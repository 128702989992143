import React from "react";

export default function About() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div
            className="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ minHeight: 400 }}
          >
            <div className="position-relative h-100">
              <img
                className="img-fluid position-absolute w-100 h-100"
                src="/img/bawe.jpg"
                alt
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 className="section-title bg-white text-start text-primary pe-3">
              About Us
            </h6>
            <h1 className="mb-4">
              Welcome to <span className="text-primary">Ocean tour&travels</span>
            </h1>
            <p className="mb-4">
              You can rely on us for your taxi services and Zanzibar day tours
              as we are available 24/7 weeks, you can message us and we will do
              a quick response to reach you for your services We drive you
              smoothly and comfortably to your hotel after you arrive in
              Zanzibar airport/ferry to your hotel within an air-conditioned car
              and well trained and professional driver who will give an
              excellent services while onboard
            </p>

            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Pristine beaches with turquoise waters.
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Explore historic Stone Town charm.
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />5 Star
                  Exotic spices on fragrant tours.
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Red Colobus monkeys in Jozani.
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Vibrant Swahili culture and heritage.
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Visit ancient slave trade chambers.
                </p>
              </div>
            </div>
            <a className="btn btn-primary py-3 px-5 mt-2" href>
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
