import React from 'react'
import data from '../../data/team.json'

export default function Team() {
  return (
    <div className="container-xxl py-5">
    <div className="container">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title bg-white text-center text-primary px-3">
          Travel Guide
        </h6>
        <h1 className="mb-5">Meet Our Guide</h1>
      </div>
      <div className="row g-4">
      {data.map((team, index) => (

        <div
          className="col-lg-3 col-md-6 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="team-item">
            <div className="overflow-hidden">
              <img className="img-fluid" src={team.photo} alt />
            </div>
            <div
              className="position-relative d-flex justify-content-center"
              style={{ marginTop: "-19px" }}
            >
              <a className="btn btn-square mx-1" href>
                <i className="fab fa-facebook-f" />
              </a>
              <a className="btn btn-square mx-1" href>
                <i className="fab fa-twitter" />
              </a>
              <a className="btn btn-square mx-1" href>
                <i className="fab fa-instagram" />
              </a>
            </div>
            <div className="text-center p-4">
              <h5 className="mb-0">{team.name}</h5>
              <small>{team.addrass}</small>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  </div>
  )
}
