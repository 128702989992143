import React from "react";

export default function Home() {


  return (
    <>
      {/* Carousel Start */}
      <div
        className="container-fluid p-0 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            >
              <img className='img-fluid' src="/img/back1.jpg" alt="Image" />
            </button>
            <button
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            >
              <img className="img-fluid" src="/img/back5.jpg" alt="Image" />
            </button>
            <button
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            >
              <img className="img-fluid" src="/img/red1.jpg" alt="Image" />
            </button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100 c-photo"
                src="/img/back1.jpg"
                alt="Image"
              />
              <div className="carousel-caption">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h4 className="text-white text-uppercase mb-4 animated zoomIn">
                    Stunning Beaches
                  </h4>
                  <h1 className="display-6 text-white mb-0 animated zoomIn">
                    Pristine beaches with turquoise waters.
                  </h1>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="w-100 c-photo"
                src="/img/back5.jpg"
                alt="Image"
              />
              <div className="carousel-caption">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h4 className="text-white text-uppercase mb-4 animated zoomIn">
                    Rich History & Culture
                  </h4>
                  <h1 className="display-7 text-white mb-0 animated zoomIn">
                    Explore historic Stone Town charm.
                  </h1>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="w-100 c-photo"
                src="/img/red1.jpg"
                alt="Image"
              />
              <div className="carousel-caption">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h4 className="text-white text-uppercase mb-4 animated zoomIn">
                    Red Colobus monkeys in Jozani.
                  </h4>
                  <h1 className="display-7 text-white mb-0 animated zoomIn">
                    Jozani Forest are a rare and endangered species unique to Zanzibar.
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
    </>
  );
}


{/* {posts?.reduce((acc, post) => {
            if (acc.length < 100) {
              acc.push(
                <div class="carousel-item home-header">
                <Image src={post.photo} width={1000} height={650} class="d-block w-100 c-pich" alt="..." />
                <div class="carousel-caption d-none d-md-block">
                  <div className="container-fluid py-3 mb-3">
                    <div className="container py-5">
                      <div className="row justify-content-center py-sm-0 py-5">
                        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                          <h1 className="display-3 text-white mb-3 animated slideInDown header-h1">
                            Enjoy Your Vacation With Us
                          </h1>
                          <p className="text-white mb-4 animated slideInDown header-p">
                            Tempor erat elitr rebum at clita diam amet diam et eos
                            erat ipsum lorem sit
                          </p>
                          <div className="position-relative w-75 mx-auto animated slideInDown">
                            <input
                              className="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
                              type="text"
                              placeholder="Eg: Thailand"
                            />
                            <button
                              type="button"
                              className="btn btn-primary rounded-pill py-2 px-4 position-absolute top-0 end-0 me-2"
                              style={{ marginTop: 7 }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            }
            return acc;
          }, [])} */}
