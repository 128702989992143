import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

export default function Navbar() {
  const [navStyle, setNavStyle] = useState(false);
  const location = useLocation();

  const changeNav = () => {
    if (window.scrollY > 45) {
      setNavStyle(true);
    } else {
      setNavStyle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2" />
                Tanzania, Zanzibar, StoneTown
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2" />
                +255 778 489 968
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2" />
                waririzi65@gmail.com
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="#"
              >
                <i className="fab fa-twitter fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="#"
              >
                <i className="fab fa-facebook-f fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="#"
              >
                <i className="fab fa-linkedin-in fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="#"
              >
                <i className="fab fa-instagram fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square"
                href="#"
              >
                <i className="fab fa-youtube fw-normal" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* nav */}
      <div className="container-fluid position-relative p-0">
        <nav className={`navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-1 py-lg-0 ${navStyle ? 'sticky-top shadow-sm' : ''}`}>
          <a href="/" className="navbar-brand p-0">
            <h1 className="text-primary fs-5 m-0">
              <i className="fa fa-map-marker-alt me-2" />
              The ocean tour&travels
            </h1>
            {/* <img src="img/logo.png" alt="Logo"> */}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>
                Home
              </Link>
              <Link to="/tour" className={`nav-item nav-link ${location.pathname === '/tour' ? 'active' : ''}`}>
                Tour
              </Link>
              {/* <Link to="/destination" className={`nav-item nav-link ${location.pathname === '/destination' ? 'active' : ''}`}>
                Destination
              </Link> */}
              <Link to="/booking" className={`nav-item nav-link ${location.pathname === '/booking' ? 'active' : ''}`}>
                booking
              </Link>
              <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>
                Contact
              </Link>
            </div>
            <a href="#" className="btn btn-primary rounded-pill py-2 px-4">
              Register
            </a>
          </div>
        </nav>
      </div>
      {/* end nav */}
    </>
  );
}
