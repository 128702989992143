import React from "react";

export default function ContactPage() {
  return (
    <>
      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-1 mb-1 contact-header">
          <div class="container py-5">
            <div class="row justify-content-center py-5">
              <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                <h1 class="display-3 text-white animated slideInDown">
                  Contact Us
                </h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="#">Pages</a>
                    </li>
                    <li
                      class="breadcrumb-item text-white active"
                      aria-current="page"
                    >
                      Contact
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Contact Us
            </h6>
            <h1 class="mb-5">Contact For Any Query</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <h5>Get In Touch</h5>
              <p class="mb-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos
              </p>
              <div class="d-flex align-items-center mb-4">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i class="fa fa-map-marker-alt text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="text-primary">Office</h5>
                  <p class="mb-0">Kendwe, Zanzibar, Tanzani</p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="text-primary">Mobile</h5>
                  <p class="mb-0">+255 654 795 912</p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i class="fa fa-envelope-open text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="text-primary">Email</h5>
                  <p class="mb-0">mkimbizikhamis@gmail.com</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <iframe
                class="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3931.928184064299!2d39.27429971399088!3d-5.757824078998795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4cb2e711303b%3A0xdfcc5d6d4972ef0d!2sKendwa%2C%20Tanzania!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"                frameborder="0"
                // style="min-height: 300px; border:0;"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div class="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
              <form>
                <div class="row g-3">
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label for="name">Your Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Your Email"
                      />
                      <label for="email">Your Email</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                      />
                      <label for="subject">Subject</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        // style="height: 100px"stylestyle
                      ></textarea>
                      <label for="message">Message</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary w-100 py-3" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
