// import React from "react";
// // import { useQuery } from "react-query";
// // import { fetchExcursions, fetchExcursionsPhotos } from "../../../api/ExcursionAPI";
// import tourData from '../../data/tourData.json';
// import tourPhoto from '../../data/tourPhoto.json';
// import { Link } from "react-router-dom";

// export default function TourPage() {

//   // const { data: excursions } = useQuery("excursions", fetchExcursions);
//   // const { data: excursionPhotos } = useQuery(
//   //   "excursionPhotos",
//   //   fetchExcursionsPhotos
//   // );

//   const ParagraphWithLimit = ({ text, limit }) => {
//     const words = text.split(" ");
//     const truncatedText = words.slice(0, limit).join(" ");
//     const remainingCount = words.length - limit;

//     return (
//       <div>
//         <p>
//           {truncatedText}
//           {remainingCount > 0 && <span> ...</span>}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <div class="container-fluid position-relative p-0">
//         <div class="container-fluid bg-primary py-1 mb-1 contact-header">
//           <div class="container py-5">
//             <div class="row justify-content-center py-5">
//               <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
//                 <h1 class="display-3 text-white animated slideInDown">
//                   Our Tours
//                 </h1>
//                 <nav aria-label="breadcrumb">
//                   <ol class="breadcrumb justify-content-center">
//                     <li class="breadcrumb-item">
//                       <a href="#">Home</a>
//                     </li>
//                     <li class="breadcrumb-item">
//                       <a href="#">Pages</a>
//                     </li>
//                     <li
//                       class="breadcrumb-item text-white active"
//                       aria-current="page"
//                     >
//                       Tour
//                     </li>
//                   </ol>
//                 </nav>
//                 <p className="fs-4 text-white mb-4 animated slideInDown">
//                   We offers excursions and day tours for your memorable holiday
//                   of your stay in Zanzibar, we have a numbers of Zanzibar
//                   excursions and day tours we offer as follows.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>


//       {/* tour Start */}
//       <div className="container-xxl py-0 destination">
//         <div className="container pt-5">
//           <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
//             <h6 class="section-title bg-white text-center text-primary px-3">
//               Available Tour
//             </h6>
//             <h1 class="mb-1">Contact For Any Query</h1>
//           </div>
//           {/* Gallery Start */}
//           <div className="container-fluid gallery py-5 my-3">
//             <div className="tab-class text-center">

//               <div className="tab-content">
//                 <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
//                   <div className="row g-2">
//                     {tourData?.reduce((acc, area, index) => {
//                       if (acc.length < 100) {
//                         acc.push(
//                           <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
//                             <div className="gallery-item h-100">
//                               {
//                                 tourPhoto
//                                   ?.map((photo, imgIndex) => {
//                                     if (photo.excursions === area.id) {
//                                       return (
//                                         <div className="photo" key={imgIndex}>
//                                           {/* <img
//                                             src={photo.image}
//                                             alt={`Image ${imgIndex}`}
//                                             className="img-fluid w-100 rounded e-photo"
//                                           /> */}
//                                           <img src={photo.image} className="img-fluid w-100 h-100 rounded" alt={`Image ${imgIndex}`} />
//                                         </div>
//                                       );
//                                     }
//                                     return null; // Return null for photos that don't match the condition
//                                   })
//                                   .filter(Boolean)[0] // Filter out null elements and take the first one
//                               }
//                               {/* <img src={area.photo} className="img-fluid w-100 h-100 rounded" alt="Image" /> */}
//                               <div className="gallery-content">
//                                 <div className="gallery-info">
//                                   <h5 className="text-white text-uppercase mb-2">{area.title}</h5>
//                                   {/* <p className='text-white'><ParagraphWithLimit text={area.longDescription} limit={15} /></p> */}
//                                   <h5 className='text-white'>
//                                     {area.price}
//                                   </h5>
//                                   {/* <a href="#" className="btn-hover text-white">View Place <i className="fa fa-arrow-right ms-2" /></a> */}
//                                 </div>
//                                 <Link to={`/tour/${area.id}`} className="btn text-white click-me">Click me to View <i className="fa fa-arrow-right ms-2" /></Link>
//                               </div>
//                             </div>
//                           </div>
//                         );
//                       }
//                       return acc;
//                     }, [])}
//                   </div>
//                 </div>

//               </div>
//             </div>

//           </div>
//           {/* Gallery End */}

//         </div>
//       </div>
//       {/* tour End */}



//     </>
//   );
// }
import React, { useEffect } from 'react';
import Isotope from 'isotope-layout';
import GLightbox from 'glightbox';
import Swiper from 'swiper';
import 'glightbox/dist/css/glightbox.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import allDest from "../../data/allDest.json"; // Assuming this has your destination data
import { Link } from "react-router-dom";
import tourData from '../../data/tourData.json';

export default function DestDataPage() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    // Initialize Isotope
    const portfolioIsotope = new Isotope('.portfolio-container', {
      itemSelector: '.portfolio-wrap',
      layoutMode: 'fitRows'
    });

    // Portfolio filters
    const portfolioFilters = document.querySelectorAll('#portfolio-flters li');
    portfolioFilters.forEach(filter => {
      filter.addEventListener('click', (e) => {
        e.preventDefault();
        portfolioFilters.forEach(el => el.classList.remove('filter-active'));
        filter.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: filter.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function () {
          AOS.refresh();
        });
      });
    });

    // Initialize GLightbox
    GLightbox({
      selector: '.portfolio-lightbox'
    });

    // Initialize Swiper
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }, []);

  const ParagraphWithLimit = ({ text, limit }) => {
    const words = text.split(" ");
    const truncatedText = words.slice(0, limit).join(" ");
    const remainingCount = words.length - limit;

    return (
      <div>
        <p>
          {truncatedText}
          {remainingCount > 0 && <span> ...</span>}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid position-relative p-0">
        <div className="container-fluid bg-primary py-1 mb-1 contact-header">
          <div className="container py-5">
            <div className="row justify-content-center py-5">
              <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                <h1 className="display-3 text-white animated slideInDown">
                  Our Destination
                </h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/pages">Pages</Link>
                    </li>
                    <li className="breadcrumb-item text-white active" aria-current="page">
                      Destination
                    </li>
                  </ol>
                </nav>
                <p className="fs-4 text-white mb-4 animated slideInDown">
                  We offer destinations for your memorable holiday stay in Zanzibar. We have a number of Zanzibar destinations and day trips we offer as follows.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======= Portfolio Section ======= */}
      <section className="portfolio py-5">
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-12">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">All</li>
                <li data-filter=".North">North</li>
                <li data-filter=".South">South</li>
                <li data-filter=".Urban">Urban</li>
              </ul>
            </div>
          </div> */}
          <div className="row portfolio-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration={500}>
            {/* Map through your data to generate the portfolio items */}
            {tourData.map((Dest, index) => (
              <div key={index} className={`col-lg-3 col-md-6 portfolio-wrap ${Dest.title.replace(/\s+/g, '-')}`}>
                <div className="portfolio-item">
                  <img src={Dest.photo} className="img-fluid e-photo" alt={Dest.title} />
                  <div className="portfolio-info">
                    <h3>{Dest.title}</h3>
                    <h5 className='text-white'>{Dest.price}</h5>
                    <div>
                      <a href={Dest.photo} data-gallery="portfolioGallery" className="portfolio-lightbox" title={Dest.title}><i className="bi bi-zoom-in" /></a>
                      <Link
                        to={`/tour/${Dest.id}`}
                        title="Portfolio Details"
                        onClick={() => window.scrollTo(0, 0)}>
                        <i className="bi bi-view-list" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Portfolio Section */}
    </>
  );
}
