import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


export default function Booking() {

  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [email, setEmail] = useState();
  const [comment, setComment] = useState();
  const [destination, setDestination] = useState();

  const handleSubmit = (e) => {

    e.preventDefault()

    const serviceID = 'service_z6frw53';
    const templateID = 'template_co5bkdd';
    const publicKey = '1fwac-357goNwpVk0';

    const templateParams = {
      from_name: name,
      from_email: email,
      date: date,
      comment: comment,
      destination: destination
    }

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('email sent', response);
        setDate('');
        setEmail('');
        setComment('');
        setName('');
        setDestination('')
      })
      .catch((err) => {
        console.log('error send message', err)
      })


    emailjs.send()

  }

  return (
    <>
    
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="booking p-5">
            <div className="row g-5 align-items-center">
              <div className="col-md-6 text-white">
                <h6 className="text-white text-uppercase">Booking</h6>
                <h1 className="text-white mb-4">Online Booking</h1>
                <p className="mb-4">
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>
                <p className="mb-4">
                  We do our best to assure you a more relaxed holiday as we
                  available for your taxi transfer service to any place while
                  you are in Zanzibar.
                </p>
                <a className="btn btn-outline-light py-3 px-5 mt-2" href>
                  Read More
                </a>
              </div>
              <div className="col-md-6">
                <h1 className="text-white mb-4">Book A Tour</h1>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          id="name"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-transparent"
                          id="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="form-floating date"
                        id="date3"
                        data-target-input="nearest"
                      >
                        <input
                          type="text"
                          className="form-control bg-transparent datetimepicker-input"
                          id="datetime"
                          placeholder="Date & Time"
                          data-target="#date3"
                          data-toggle="datetimepicker"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                        <label htmlFor="datetime">Date &amp; Time</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <select
                          className="form-select bg-transparent"
                          id="select1"
                          value={destination}
                          onChange={(e) => setDestination(e.target.value)}
                        >
                          <option value={1}>Destination 1</option>
                          <option value={2}>Destination 2</option>
                          <option value={3}>Destination 3</option>
                        </select>
                        <label htmlFor="select1">Destination</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control bg-transparent"
                          placeholder="Special Request"
                          id="message"
                          style={{ height: 100 }}
                          defaultValue={""}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <label htmlFor="message">Special Request</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-outline-light w-100 py-3"
                        type="submit"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
