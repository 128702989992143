import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import data from '../../data/feedback.json';

const Feedback = () => {
  const options = {
    autoplay: true,
    smartSpeed: 1000,
    center: true,
    margin: 24,
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 3
      }
    },
    
  };

  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Testimonial
          </h6>
          <h1 className="mb-5">Our Clients Say!!!</h1>
        </div>
        <OwlCarousel className="owl-carousel testimonial-carousel position-relative" {...options}>
          {data.map((feedback, index) => (

            <div className="testimonial-item bg-white text-center border p-4">
              <img
                className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src={feedback.photo}
                style={{ width: 80, height: 80 }}
                alt={feedback.title}
              />
              <h5 className="mb-0">{feedback.client_name}</h5>
              <h6 className="mb-1 primary-text">{feedback.tour_title}</h6>
              <span className="mb-0">
                {feedback.feedback}
              </span>
            </div>
          ))}

        </OwlCarousel>
      </div>
    </div>
  );
};

export default Feedback;
