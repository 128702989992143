import { React, useEffect } from 'react';
import './App.css';
import Page from './component/user/home/Page';
import { Route, Routes } from 'react-router-dom';
import Footer from './utils/Footer';
import Navbar from './utils/Navbar';
import Spinner from './utils/Spinner';
import Booking from './component/user/booking/Booking';
import Tour from './component/user/tour/TourPage';
import DestinationPage from './component/user/destination/DestinationPage';
import ContactPage from './component/user/contact/ContactPage';
import TourById from './component/user/tour/TourById';
import DestPageById from './component/user/destination/DestPageById';

function App() {


  return (
    <>
      <Navbar />
      <Spinner />
      <Routes>
        <Route exact path="/" element={<Page />} />
        <Route path="/booking" element={<Booking />} />
        <Route path='tour'>
          <Route path="/tour" element={<Tour />} />
          <Route path=":id" element={<TourById />} />
        </Route>
        <Route path='destination'>
          <Route path="/destination" element={<DestinationPage />} />
          <Route path=":id" element={<DestPageById />} />
        </Route>
        <Route path="/contact" element={<ContactPage />} />

      </Routes>
      <Footer />
    </>
  );
}

export default App;