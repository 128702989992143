// import React from "react";
// // import { useQuery } from "react-query";
// import tourData from '../../data/tourData.json';
// import tourPhoto from '../../data/tourPhoto.json';
// // import { fetchExcursions, fetchExcursionsPhotos } from "../../../api/ExcursionAPI";
// import './home.css'
// import { Link } from "react-router-dom";

// export default function Tour() {
//   // const { data: excursions } = useQuery("excursions", fetchExcursions);
//   // const { data: excursionPhotos } = useQuery(
//   //   "excursionPhotos",
//   //   fetchExcursionsPhotos
//   // );

//   const ParagraphWithLimit = ({ text, limit }) => {
//     const words = text.split(" ");
//     const truncatedText = words.slice(0, limit).join(" ");
//     const remainingCount = words.length - limit;

//     return (
//       <div>
//         <p>
//           {truncatedText}
//           {remainingCount > 0 && <span> ...</span>}
//         </p>
//       </div>
//     );
//   };
//   return (
//     <>

//       {/* Explore Tour Start */}
//       <div className="container-fluid ExploreTour py-5">
//         <div className="container">
//           <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//             <h6 className="section-title bg-white text-center text-primary px-3">
//               Tour
//             </h6>
//             <h1 className="mb-5">Explore Tours and Excursions.</h1>
//           </div>
//           <div className="tab-class text-center">
//             <div className="tab-content">
//               <div id="NationalTab-1" className="tab-pane fade show p-0 active">
//                 <div className="row g-4">
//                   {tourData?.reduce((acc, excursion) => {
//                     if (acc.length < 8) {
//                       acc.push(
//                         <div className="col-md-6 col-lg-3">
//                           <div className="national-item">
//                             {
//                               tourPhoto?.map((photo, imgIndex) => {
//                                 if (photo.excursions === excursion.id) {
//                                   return (
//                                     <div className="photo" key={imgIndex}>
//                                       <img
//                                         src={photo.image}
//                                         alt={`Image ${imgIndex}`}
//                                         className="img-fluid w-100 rounded e-photo"
//                                       />
//                                     </div>
//                                   );
//                                 }
//                                 return null;
//                               })
//                                 .filter(Boolean)[0]
//                             }
//                             <div className="national-content">
//                               <div className="national-info">
//                                 <h5 className="text-white text-uppercase mb-2">{excursion.title}</h5>
//                                 <Link to={`/tour/${excursion.id}`} className="btn-hover text-white">View <i className="fa fa-arrow-right ms-2" /></Link>
//                               </div>
//                             </div>
//                             <div className="national-plus-icon">
//                               <a href="#" className="my-auto"><i className="fas fa-link fa-2x text-white" /></a>
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     }
//                     return acc;
//                   }, [])}
//                 </div>
//               </div>

//               <div className="national-info pt-5">
//                   <Link to={`/tour`} className="btn btn-primary rounded-pill text-white round">View More Tours <i className="fa fa-arrow-right ms-2" /></Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Explore Tour Start */}



//     </>

//   );
// }

import React from "react";
import destNorth from "../../data/destNorth.json";
import tourData from '../../data/tourData.json';
import tourPhoto from '../../data/tourPhoto.json';
import { Link } from "react-router-dom";

export default function Tour() {

  const ParagraphWithLimit = ({ text, limit }) => {
    const words = text.split(' ');
    const truncatedText = words.slice(0, limit).join(' ');
    const remainingCount = words.length - limit;

    return (
      <div>
        <p>
          {truncatedText}
          {remainingCount > 0 && <span> ...</span>}
        </p>
      </div>
    );
  };

  return (
    <div className="container-xxl py-0 destination">
      <div className="container">

        {/* Gallery Start */}
        <div className="container-fluid gallery py-0 my-3">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Tour
            </h6>
            <h2 className="mb-5">Explore Tours and Excursions.</h2>
          </div>
          <div className="tab-class text-center mb-5">

            <div className="tab-content">
              <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                <div className="row g-2">
                  {tourData?.reduce((acc, tour, index) => {
                    if (acc.length < 8) {
                      acc.push(
                        <div key={tour.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src={tour.photo} className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                              <div className="gallery-info">
                                <h5 className="text-white text-uppercase mb-2">{tour.title}</h5>
                                <p className='text-white'>
                                  <ParagraphWithLimit text={tour.longDescription} limit={18} />
                                </p>
                                <h6 className='text-white'>{tour.price}</h6>
                              </div>
                              <Link
                                to={`/tour/${tour.id}`}
                                onClick={() => window.scrollTo(0, 0)}
                                className="btn text-white click-me">
                                Click me to View <i className="fa fa-arrow-right ms-2" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return acc;
                  }, [])}
                </div>
              </div>
              <div className="national-info pt-5">
                <Link
                  to="/tour"
                  className="btn btn-primary rounded-pill text-white round"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  View More Tours <i className="fa fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>

          </div>

        </div>
        {/* Gallery End */}

      </div>
    </div>
  );
}
