import React from 'react'
import { useParams } from 'react-router-dom';
import allDest from '../../data/allDest.json';
import destPhoto from '../../data/destPhoto.json';
import { Carousel } from 'antd';


export default function DestPageById() {

  const { id } = useParams();

  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const imageStyle = {
    width: '100%',
    height: '420px',
    objectFit: 'cover'
  };

  return (
    <>
      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-1 mb-1 contact-header">
          <div class="container py-5">
            <div class="row justify-content-center py-5">
              <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                <h1 class="display-3 text-white animated slideInDown">
                  Our Destination
                </h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="#">Pages</a>
                    </li>
                    <li
                      class="breadcrumb-item text-white active"
                      aria-current="page"
                    >
                      Destination
                    </li>
                  </ol>
                </nav>
                <p className="fs-4 text-white mb-4 animated slideInDown">
                  We offers Destination for your memorable holiday of your stay
                  in Zanzibar, we have a numbers of Zanzibar destinations and
                  day tours we offer as follows.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======= Portfolio Details Section ======= */}

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          {allDest.map((destData) => {
            if (destData.id == id) {
              return (
                <div className="row gy-4">
                  <div className="col-lg-8">
                    <Carousel autoplay arrows infinite={false}>
                      {destPhoto.map((photo, index) => {
                        if (destData.id == photo.destinationArea) {
                          return (
                            <>
                              <div key={index}>
                                <img src={photo.image} alt={`Slide ${index + 1}`} style={imageStyle} />
                              </div>
                            </>
                          );
                        }
                        return null;
                      })}
                    </Carousel>
                    <h4 className="portfolio-title pt-2">{destData.title}</h4>
                    <p>
                      {destData.longDescription}
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <div className="portfolio-description">
                    </div>
                    <div className="portfolio-info">
                      <h3>Information.</h3>
                      <ul>
                        <li><strong>Price :</strong>{destData.price} / Person</li>
                        <li><strong>Time</strong>: Day&night</li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </section>
      {/* End Portfolio Details Section */}
    </>
  )
}
