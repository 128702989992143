import React from "react";
import Home from "./Home";
import About from "./About";
import Service from "./Service";
import Destination from "./Galary";
import Booking from "./Booking";
import Team from "./Team";
import Feedback from "./Feedback";
import Tour from "./Tour";
import { useQuery } from 'react-query';


export default function Page() {


  return (
    <>
      <Home />
      <About />
      <Tour />
      {/* <Destination /> */}


      <Booking />

      {/* <Service /> */}

      <Team />

      <Feedback/>

    </>
  );
}
