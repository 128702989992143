import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import tourData from '../../data/tourData.json';
import tourPhoto from '../../data/tourPhoto.json'
import { Carousel, Image, Pagination } from 'antd';

export default function TourById() {

  const { id } = useParams();



  const imageStyle = {
    width: '100%',
    height: '420px',
    objectFit: 'cover'
  };




  return (
    <>
      {
        tourData.map((tourInf) => {
          if (tourInf.id == id) {
            return (
              <>
                <div className="container-fluid position-relative p-0">
                  <div className="container-fluid bg-primary py-1 mb-1 contact-header">
                    <div className="container py-5">
                      <div className="row justify-content-center py-5">
                        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                          <h1 className="display-8 text-white animated slideInDown">
                            {tourInf.title}
                          </h1>
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                              <li className="breadcrumb-item">
                                <a href="#">Home</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#">Pages</a>
                              </li>
                              <li
                                className="breadcrumb-item text-white active"
                                aria-current="page"
                              >
                                <a href="#">tour</a>
                              </li>
                              <li
                                className="breadcrumb-item text-white active"
                                aria-current="page"
                              >
                                {tourInf.id}
                              </li>
                            </ol>
                          </nav>
                          <p className="fs-5 text-white mb-4 animated slideInDown">
                            We offer excursions and day tours for your memorable holiday
                            of your stay in Zanzibar, we have a number of Zanzibar
                            excursions and day tours we offer as follows.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="blog" className="blog">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 entries">
                        <article className="entry" key={tourInf.id}>
                          <div className="col-lg-12">
                            <h4 className="portfolio-title entry-title pt-2">
                              <a href="blog-single.html">{tourInf.title}</a>
                            </h4>
                            <div className="entry-meta">
                              <ul>
                                <li className="d-flex align-items-center"><i className="bi bi-clock" /> <a href="blog-single.html"><time dateTime="2020-01-01">Day & night  </time></a></li>
                              </ul>
                            </div>
                            <p>
                              {tourInf.longDescription}
                            </p>
                          </div>
                          {/* <p>
                            share:{tourInf.price}
                          </p> */}
                          <p>
                            Price: {tourInf.price}
                          </p>
                          <div className="read-more">
                            <a href="/booking">Book Now</a>
                          </div>
                        </article>

                        <article className="entry">
                          <h2 className="entry-title">
                            <a href="blog-single.html">{tourInf.title} Gallery</a>
                          </h2>
                          <div className="entry-content">
                            <p>
                              <Carousel autoplay arrows infinite={false}>
                                {tourPhoto.map((photo) => {
                                  if (tourInf.id === photo.excursions) {
                                    return (
                                      <div key={photo.id}>
                                        <img src={photo.image} alt={`Slide ${photo.id}`} style={imageStyle} />
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </Carousel>
                            </p>
                            <p className='text my-4'>
                              <div className='text my-2'>
                                <span className='fs-5'>Tour Highlight </span>
                                <p className='my-1'>{tourInf.highlight}</p>
                              </div>
                              <div className='text my-2'>
                                <span className='fs-5'>Tour Inclusion </span>
                                <p className='my-2'>{tourInf.inclusion}</p>
                              </div>
                              <div className='text my-2'>
                                <span className='fs-5'>Tour Exclusion </span>
                                <p className='my-2'>{tourInf.exclusion}</p>
                              </div>
                            </p>

                            <div className="read-more">
                              <a href="blog-single.html">Read More</a>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="col-lg-4">
                        <div className="sidebar">
                          <h3 className="sidebar-title">Search</h3>
                          <div className="sidebar-item search-form">
                            <form action>
                              <input type="text" />
                              <button type="submit"><i className="bi bi-search" /></button>
                            </form>
                          </div>
                          <h3 className="sidebar-title">Other Tour</h3>
                          <div className="sidebar-item categories">
                            <ul>
                              {tourData.map((tourInf) => (
                                <li><Link
                                  to={`/tour/${tourInf.id}`}
                                  onClick={() => window.scrollTo(0, 0)}>
                                  {tourInf.title}
                                </Link></li>
                              ))}

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          }
          return null;
        })
      }
    </>
  )
}
