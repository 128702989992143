import React, { useEffect } from 'react';
import Isotope from 'isotope-layout';
import GLightbox from 'glightbox';
import Swiper from 'swiper';
// import 'swiper/swiper-bundle.min.css';
import 'glightbox/dist/css/glightbox.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import allDest from "../../data/allDest.json";
import { Link } from "react-router-dom";

export default function DestDataPage() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    // Initialize Isotope
    let portfolioIsotope = new Isotope('.portfolio-container', {
      itemSelector: '.portfolio-wrap',
      layoutMode: 'fitRows'
    });

    // Portfolio filters
    const portfolioFilters = document.querySelectorAll('#portfolio-flters li');
    portfolioFilters.forEach(filter => {
      filter.addEventListener('click', (e) => {
        e.preventDefault();
        portfolioFilters.forEach(el => el.classList.remove('filter-active'));
        filter.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: filter.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function () {
          AOS.refresh();
        });
      });
    });

    // Initialize GLightbox
    GLightbox({
      selector: '.portfolio-lightbox'
    });

    // Initialize Swiper
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }, []);

  const ParagraphWithLimit = ({ text, limit }) => {
    const words = text.split(" ");
    const truncatedText = words.slice(0, limit).join(" ");
    const remainingCount = words.length - limit;

    return (
      <div>
        <p>
          {truncatedText}
          {remainingCount > 0 && <span> ...</span>}
        </p>
      </div>
    );
  };

  return (
    <>

      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-1 mb-1 contact-header">
          <div class="container py-5">
            <div class="row justify-content-center py-5">
              <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                <h1 class="display-3 text-white animated slideInDown">
                  Our Destination
                </h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="#">Pages</a>
                    </li>
                    <li
                      class="breadcrumb-item text-white active"
                      aria-current="page"
                    >
                      Destination
                    </li>
                  </ol>
                </nav>
                <p className="fs-4 text-white mb-4 animated slideInDown">
                  We offers Destination for your memorable holiday of your stay
                  in Zanzibar, we have a numbers of Zanzibar destinations and
                  day DestDatas we offer as follows.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======= Portfolio Section ======= */}
      <section className="portfolio py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">All</li>
                <li data-filter=".North">North</li>
                <li data-filter=".South">South</li>
                <li data-filter=".Urban">Urban</li>
              </ul>
            </div>
          </div>
          <div className="row portfolio-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration={500}>
            {/* Map through your data to generate the portfolio items */}
            {allDest.map((DestData, index) => (
              <div key={index} className={`col-lg-4 col-md-6 portfolio-wrap ${DestData.destination.title}`}>
                <div className="portfolio-item">
                  <img src={DestData.photo} className="img-fluid e-photo" alt={DestData.title} />
                  <div className="portfolio-info">
                    <h3>{DestData.title}</h3>
                    <h5 className='text-white'>{DestData.price}</h5>
                    <div>
                      <a href={DestData.photo} data-gallery="portfolioGallery" className="portfolio-lightbox" title={DestData.title}><i class="bi bi-zoom-in" /></a>
                      <Link to={`/destination/${DestData.id}`} title="Portfolio Details"><i class="bi bi-view-list" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Portfolio Section */}
    </>
  );
}

